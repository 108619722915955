window.addEventListener('load', () => {
    document.onkeydown = e => {
        const modalHashes = [
            // '#petr-zvonicek-modal',
            // '#liliia-abdulina-modal',
            // '#chris-eidhof-modal',
            // '#petr-mara-modal',
            // '#lubo-smid-modal',
            // '#jana-srotirova-modal',
            // '#danny-preussler-modal',
            // '#sebastian-aigner-modal',
            // '#marton-braun-modal',
            // '#lukas-camra-modal',
            // '#ashley-davies-modal',
            // '#james-montemagno-modal',
            // '#jan-masek-modal',
            // '#ahmed-tikiwa-modal',
            // '#lukas-klingsbo-modal',
            // '#kamil-borzym-modal',
            // '#istvan-juhos-modal',
            // '#mikolas-stuchlik-modal',
            // '#petr-dvorak-modal',
            // '#pavel-zak-modal',
            // '#david-bilik-modal',
            // '#igor-rosocha-modal',
            // '#veronika-zelinkova-modal',
            // '#michal-matl-modal',
            // '#filip-dolnik-modal',
            // '#christopher-lawson-modal',
            // '#matej-jirasek-modal',
            // '#baha-chbani-modal',
            // '#marian-kamenistak-modal',
            // -------- 2025
            '#martin-holodniok-michal-klement-modal',
            '#michal-ursiny-modal',
            '#lukas-balazevic-modal',
            '#manuel-carrasco-molina-modal',
            '#daniel-steinberg-modal',
            '#petr-nymsa-jan-bittner-modal',
            '#tomas-mlynaric-modal',
            '#elaine-dias-batista-modal',
            '#sebastian-sellmair-modal',
            '#leopold-podmolik-modal',
            '#arnaud-giuliani-modal',
            '#tomas-voves-modal',
            '#erica-engle-modal',
            '#daria-orlova-modal',
            '#lukas-hosnedl-modal',
            '#petr-tomasek-modal',
            '#andrea-cioccarelli-modal',
            '#sylvia-dieckmann-modal',
            '#filip-dolnik-modal',
            '#tim-condon-modal',
            '#dominik-simonik-modal',
            '#aline-borges-modal',
            '#istvan-juhos-modal',
            '#muralidharan-kathiresan-modal',
            '#jan-ehrenberger-modal',
            '#dominik-titl-modal',
            '#jitka-pfeiferova-modal',
            '#matej-semancik-modal',
            '#ash-davies-modal',
            '#vladislav-komkov-modal',
            '#lukas-valenta-modal',
            '#martin-sumera-modal',
            '#tomasz-sluszniak-modal',
            '#balazs-gerlei-modal',
            '#daniel-ondruj-modal',
            '#srajer-picha-modal',
            '#nicolo-stanciu-modal',
        ];
        const modalIndex = modalHashes.indexOf(window.location.hash);
        if (modalIndex !== -1) {
            if (e.key === 'ArrowRight') {
                if (modalIndex + 1 < modalHashes.length) {
                    location.hash = modalHashes[modalIndex + 1];
                }
            } else if (e.key === 'ArrowLeft') {
                if (modalIndex - 1 >= 0) {
                    location.hash = modalHashes[modalIndex - 1];
                }
            }
        }
    };
});
