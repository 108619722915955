const countBackgroundSize = () => {
    const backgroundImageHeight = 801;
    const backgroundImageWidth = 1200;
    const headerSection = document.getElementById('js-header-section');
    if (headerSection.offsetWidth / headerSection.offsetHeight < backgroundImageWidth / backgroundImageHeight) {
        headerSection.style.backgroundSize = `auto ${headerSection.offsetHeight}px`;
    } else {
        headerSection.style.backgroundSize = `${headerSection.offsetWidth}px auto`;
    }
};

const countVideoSize = () => {
    const videoHeight = 928;
    const videoWidth = 1920;
    const headerSection = document.getElementById('js-header-section');
    const introVideo = document.getElementById('js-intro-video');
    if (headerSection.offsetWidth / headerSection.offsetHeight < videoWidth / videoHeight) {
        introVideo.style.height = `${headerSection.offsetHeight}px`;
        introVideo.style.width = null;
    } else {
        introVideo.style.height = null;
        introVideo.style.width = `${headerSection.offsetWidth}px`;
    }
};

const setupVideo = () => {
    const vid = document.getElementById('js-intro-video');
    vid.loop = true;
    vid.muted = true;
    vid.playbackRate = 0.8;
    vid.play();

    const siteHeader = document.getElementById('site-header');
    if ('connection' in navigator) {
        if (navigator.connection.saveData) {
            siteHeader.classList.add('background-photo');
            vid.style.display = 'none';
        }
    }
};

window.addEventListener('resize', () => {
    countBackgroundSize();
    countVideoSize();
});
window.addEventListener('load', () => {
    setupVideo();
    countBackgroundSize();
    countVideoSize();
});
