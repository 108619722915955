window.addEventListener('load', () => {
    const menuButton = document.getElementById('js-menu-button');
    const menuImage = document.getElementById('js-menu-icon');
    const menu = document.getElementById('js-menu');
    const headerSection = document.querySelector('.header-section');
    const logo = document.querySelector('.top-line .logo');

    let open = false;

    menuButton.addEventListener('click', () => {
        if (open) {
            menuImage.src = './images/menu-icon.svg';
            logo.src = './images/mDevCamp-logo-new.svg';
            open = false;
            menu.classList.add('disabled');
            headerSection.classList.remove('menu-open');
        } else {
            menuImage.src = './images/x-orange.svg';
            logo.src = './images/mDevCamp-logo-purple.svg';
            open = true;
            menu.classList.remove('disabled');
            headerSection.classList.add('menu-open');
        }
    });
});
